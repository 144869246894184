import React from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'
// Components
import { Link } from 'gatsby'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Layout } from '../components/commons'
import { Box, Heading, Text } from '../atoms'
import { mediaQueries } from '../theme'

interface Page {
  id: string
  pageInfo: {
    slug: string
    name: string
  }
}

interface Templates {
  nodes: Page[]
}

type Template = Page

interface DataQuery {
  company: Templates
  product: Templates
  pricing: Template
  contact: Templates
  marketing: Templates
  legals: Templates
  blog: Template
  categories: Templates
  authors: Templates
  articles: {
    nodes: {
      id: string
      title: string
      slug: string
    }[]
  }
  resourcesLanding: Template
  resourceCategories: Templates
  resources: Templates
  customerStoriesLanding: Template
  customerStoryCategories: Templates
  customerStories: {
    nodes: {
      id: string
      title: string
      slug: string
    }[]
  }
}

const Sitemap: React.FC<PageProps<DataQuery, { title: string }>> = ({ data, pageContext }) => {
  const {
    company,
    product,
    pricing,
    contact,
    marketing,
    legals,
    blog,
    categories,
    authors,
    articles,
    resourcesLanding,
    resourceCategories,
    resources,
    customerStoriesLanding,
    customerStoryCategories,
    customerStories,
  } = data

  const pagesList: Page[] = [
    { id: 'home', pageInfo: { slug: '/', name: 'Home' } },
    ...product.nodes,
    pricing,
    ...company.nodes,
    ...contact.nodes,
    ...marketing.nodes,
    blog,
    customerStoriesLanding,
    resourcesLanding,
    ...legals.nodes,
  ]

  return (
    <Layout headerDark={false}>
      <Box pt={[80, 120, 160]}>
        <Grid>
          <Row>
            <Col xs={12}>
              <Heading>{pageContext.title}</Heading>
            </Col>
          </Row>
          {/* Pages */}
          <Box mt={[36, 36, 80]}>
            <Row>
              <Col xs={12}>
                <Text fontWeight={'semi'} fontSize={4}>
                  Pages
                </Text>
                <Box mt={5}>
                  <GridContainer>
                    {pagesList.map((item) => (
                      <div key={item.id}>
                        <Link to={`/${item.pageInfo.slug}`}>
                          <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                            {item.pageInfo.name}
                          </Text>
                        </Link>
                      </div>
                    ))}
                  </GridContainer>
                </Box>
              </Col>
            </Row>
          </Box>

          {/* Resources */}
          <Box mt={[36, 36, 80]}>
            <Row style={{ gap: '36px 0' }}>
              <Col xs={12} md={6}>
                <Text fontWeight={'semi'} fontSize={4}>
                  Resource Categories
                </Text>
                <Box mt={5}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(1, 1fr)',
                      gridColumnGap: '36px',
                      gridRowGap: '8px',
                    }}
                  >
                    {resourceCategories.nodes.map((item) => (
                      <div key={item.id}>
                        <Link to={`/${item.pageInfo.slug}`}>
                          <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                            {item.pageInfo.name}
                          </Text>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Box>
              </Col>
              <Col xs={12} md={6}>
                <Text fontWeight={'semi'} fontSize={4}>
                  Resources
                </Text>
                <Box mt={5}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(1, 1fr)',
                      gridColumnGap: '36px',
                      gridRowGap: '8px',
                    }}
                  >
                    {resources.nodes.map((item) => (
                      <div key={item.id}>
                        <Link to={`/${item.pageInfo.slug}`}>
                          <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                            {item.pageInfo.name}
                          </Text>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>

          {/* Blog Cat / Customer story categories / Authors  */}
          <Box mt={[36, 36, 80]}>
            <Row style={{ gap: '36px 0' }}>
              <Col xs={12} md={6}>
                <Box>
                  <Text fontWeight={'semi'} fontSize={4}>
                    Blog Categories
                  </Text>
                  <Box mt={5}>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        gridColumnGap: '36px',
                        gridRowGap: '8px',
                      }}
                    >
                      {categories.nodes.map((item) => (
                        <div key={item.id}>
                          <Link to={`/${item.pageInfo.slug}`}>
                            <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                              {item.pageInfo.name}
                            </Text>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </Box>
                </Box>
                <Box mt={[36, 36]}>
                  <Text fontWeight={'semi'} fontSize={4}>
                    Customer Story Categories
                  </Text>
                  <Box mt={5}>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        gridColumnGap: '36px',
                        gridRowGap: '8px',
                      }}
                    >
                      {customerStoryCategories.nodes.map((item) => (
                        <div key={item.id}>
                          <Link to={`/${item.pageInfo.slug}`}>
                            <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                              {item.pageInfo.name}
                            </Text>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </Box>
                </Box>
              </Col>
              <Col xs={12} md={6}>
                <Text fontWeight={'semi'} fontSize={4}>
                  Authors
                </Text>
                <Box mt={5}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(1, 1fr)',
                      gridColumnGap: '36px',
                      gridRowGap: '8px',
                    }}
                  >
                    {authors.nodes.map((item) => (
                      <div key={item.id}>
                        <Link to={`/${item.pageInfo.slug}`}>
                          <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                            {item.pageInfo.name}
                          </Text>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>

          {/* Articles*/}
          <Box mt={[36, 36, 80]}>
            <Row>
              <Col xs={12}>
                <Text fontWeight={'semi'} fontSize={4}>
                  Articles
                </Text>
                <Box mt={5}>
                  <GridContainer>
                    {articles.nodes.map((item) => (
                      <div key={item.id}>
                        <Link to={`/${item.slug}`}>
                          <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                            {item.title}
                          </Text>
                        </Link>
                      </div>
                    ))}
                  </GridContainer>
                </Box>
              </Col>
            </Row>
          </Box>

          {/* Customer Stories */}
          <Box mt={[36, 36, 80]}>
            <Row>
              <Col xs={12}>
                <Text fontWeight={'semi'} fontSize={4}>
                  Customer Stories
                </Text>
                <Box mt={5}>
                  <GridContainer>
                    {customerStories.nodes.map((item) => (
                      <div key={item.id}>
                        <Link to={`/${item.slug}`}>
                          <Text color={'purpleBlue'} fontWeight={'normal'} textDecoration={'underline'}>
                            {item.title}
                          </Text>
                        </Link>
                      </div>
                    ))}
                  </GridContainer>
                </Box>
              </Col>
            </Row>
          </Box>
        </Grid>
      </Box>
    </Layout>
  )
}

const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 36px;
  grid-row-gap: 8px;

  ${mediaQueries.greaterThan('md')`
    grid-template-columns: repeat(2, 1fr);
  `}
`

export const query = graphql`
  query SitemapQuery {
    company: allDatoCmsCompany {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    product: allDatoCmsProduct {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    pricing: datoCmsPricing {
      id
      pageInfo {
        slug
        name
      }
    }

    contact: allDatoCmsContact {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    marketing: allDatoCmsMarketing {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    legals: allDatoCmsLegal {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    blog: datoCmsBlog {
      id
      pageInfo {
        name
        slug
      }
    }

    categories: allDatoCmsCategory {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    authors: allDatoCmsAuthor {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    articles: allDatoCmsArticle(sort: { order: DESC, fields: meta___firstPublishedAt }) {
      nodes {
        id
        title
        slug
      }
    }

    resourcesLanding: datoCmsResourcesLanding {
      id
      pageInfo {
        name
        slug
      }
    }

    resourceCategories: allDatoCmsResourceCategory {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    resources: allDatoCmsResource {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    customerStoriesLanding: datoCmsCustomerStoriesLanding {
      id
      pageInfo {
        name
        slug
      }
    }

    customerStoryCategories: allDatoCmsCustomerStoriesCategory {
      nodes {
        id
        pageInfo {
          slug
          name
        }
      }
    }

    customerStories: allDatoCmsCustomerStory(sort: { order: DESC, fields: meta___firstPublishedAt }) {
      nodes {
        id
        title
        slug
      }
    }
  }
`

export default Sitemap
